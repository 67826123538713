@import url('../variable.less');

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  // vertical-align: inherit;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
img,
ins,
kbd,
q,
s,
samp,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
em,
strong,
button,
blockquote,
small,
strike,
sub,
sup,
tt,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  // vertical-align: baseline;
}

body {
  &.step1 {
    @media @screen767 {
      margin-bottom: 150px;
    }
  }

  &.step2 {
    @media @screen767 {
      margin-bottom: 211px;
    }
  }

  &.step3 {
    @media @screen767 {
      margin-bottom: 90px;
    }
  }

  &.account-setting-page {
    @media @screen767 {
      margin-bottom: 72px;
    }
  }

  &.manage-donation-page {
    @media @screen767 {
      margin-bottom: 72px;
    }
  }
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  scroll-behavior: smooth;
}

a {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

//=========================================
/********** margin padding gap function start here **************/
//=========================================
.for(@i, @n) {
  .-each(@i);
}

.for(@n) when (isnumber(@n)) {
  .for(1, @n);
}

.for(@i, @n) when not (@i =@n) {
  .for((@i + (@n - @i) / abs(@n - @i)), @n);
}

//--------  End of for loop  ---------//
//=========================================
//              FOR EACH LOOP             =
//=========================================
.for(@array) when (default()) {
  .for-impl_(length(@array));
}

.for-impl_(@i) when (@i > 1) {
  .for-impl_((@i - 1));
}

.for-impl_(@i) when (@i > 0) {
  .-each(extract(@array, @i));
}

//--------  End of for each loop  ---------//

@values-array: 0, 2, 6, 4, 8, 12, 16, 20, 24, 32, 40, 48;

// init functions
.make-space() {
  .for(@values-array);

  .-each(@value) {
    .gap-@{value} {
      gap: ~'@{value}px';
    }

    .p-@{value} {
      padding: ~'@{value}px';
    }

    .pt-@{value} {
      padding-top: ~'@{value}px';
    }

    .pl-@{value} {
      padding-left: ~'@{value}px';
    }

    .pr-@{value} {
      padding-right: ~'@{value}px';
    }

    .pb-@{value} {
      padding-bottom: ~'@{value}px';
    }

    .m-@{value} {
      margin: ~'@{value}px';
    }

    .mt-@{value} {
      margin-top: ~'@{value}px';
    }

    .ml-@{value} {
      margin-left: ~'@{value}px';
    }

    .mr-@{value} {
      margin-right: ~'@{value}px';
    }

    .mb-@{value} {
      margin-bottom: ~'@{value}px';
    }
  }
}

.make-space();

.m-auto {
  margin: auto;
}

//=========================================
/********** margin padding gap function end here **************/
//=========================================
//=========================================
/********** text alignment start here **************/
//=========================================
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

//=========================================
/********** text alignment end here **************/
//=========================================
.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.m-hide {
  display: block;

  @media @screen767 {
    display: none;
  }
}

.d-hide {
  display: none;

  @media @screen991 {
    display: block;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

//border style
.b-0 {
  border: 0;
}

.b-1 {
  border-spacing: 1px;
}

.b-2 {
  border-spacing: 2px;
}

.b-3 {
  border-spacing: 3px;
}

.b-solid {
  border-style: solid;
}

//border style
/*position class start here*/
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

/*position class end here*/
/*loader css start here*/
.loader {
  .d-flex;
  .align-center;
  .justify-center;
  .fixed;
  .w-full;
  .h-full;
}

/*loader css end here*/
//=========================================
/********** flex box class start here **************/
//=========================================

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.flex-grow-2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.flex-shrink-2 {
  -ms-flex-negative: 2;
  flex-shrink: 2;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.flex-1 {
  flex: 1;
}

//=========================================
/********** flex box class end here **************/
//=========================================
/*avatar css start here*/
.ant-avatar {
  height: 48px;
  width: 48px;
  display: inline-flex;
  align-items: center;

  &.ant-avatar-sm {
    height: 40px;
    width: 40px;
  }

  &.ant-avatar-lg {
    height: 64px;
    width: 64px;

    @media @screen767 {
      height: 48px;
      width: 48px;
    }
  }

  &.avatar-xl {
    height: 96px;
    width: 96px;

    @media @screen767 {
      height: 64px;
      width: 64px;
    }
  }

  &.avatar-xxl {
    height: 128px;
    width: 128px;

    @media @screen767 {
      height: 96px;
      width: 96px;
    }
  }
}

/*avatar css end here*/
/*alert css start here*/
.cs-alert {
  border-radius: 8px;

  &.cd-dc-light {
    .ant-alert-content {
      .ant-alert-message {
        color: @tq-neutral-0;
      }
    }
  }

  &.cd-dc-dark {
    .ant-alert-content {
      .ant-alert-message {
        color: @tq-neutral-900;
      }
    }

    .ant-alert-icon {
      color: @tq-neutral-900;
    }

    .ant-alert-close-icon {
      color: @tq-neutral-900;
    }
  }

  .ant-alert-icon {
    color: inherit;
  }

  .ant-alert-close-icon {
    color: inherit;
  }

  .ant-alert-content {
    .ant-alert-message {
      color: inherit;
      font: @tq-text-md-bold;
      .mb-4;
    }

    .ant-alert-description {
      font: @tq-text-small;
    }
  }
}

/*alert css end here*/
/*Accordion css start here*/
.cs-accordion {
  .ant-collapse {
    border: 0;

    > .ant-collapse-item {
      border: 1px solid @tq-border-bottom;
      border-radius: 8px;
      background: @tq-neutral-200;
      .mb-8;
      overflow: hidden;

      > .ant-collapse-header {
        background: @tq-neutral-200;

        .ant-collapse-header-text {
          font: @tq-heading-h5;

          @media @screen767 {
            font: @tq-heading-h6;
          }
        }
      }

      .ant-collapse-content {
        border: 0;

        .ant-collapse-content-box {
          padding: 0 16px 16px;
          background: @tq-neutral-200;
        }
      }
    }
  }
}

/*Accordion css end here*/
/*button css start here*/
// .ant-btn-default:disabled {
//   background: @tq-neutral-500;
//   color: @tq-neutral-100;
//   border: 1px solid @tq-neutral-900;
// }

.cs-button {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &.between {
    justify-content: space-between;
  }

  &.ant-btn.ant-btn-lg {
    font: @tq-button-l;
    letter-spacing: 0.01em;

    @media @screen767 {
      height: 50px;
      font: @tq-button-m;
      letter-spacing: 0;
      padding: 0 24px;
    }
  }

  &.ant-btn {
    font: @tq-button-m;
    letter-spacing: 0.01em;

    @media @screen767 {
      height: 42px;
      letter-spacing: 0;
    }
  }

  &.ant-btn.ant-btn-sm {
    .button-s;
    padding: 0 16px;

    @media @screen767 {
      height: 32px;
    }
  }

  span {
    display: flex;
    align-items: center;
  }

  // svg {
  //   &:first-child {
  //     .mr-8;
  //   }

  //   &:last-child {
  //     .ml-8;
  //   }
  // }
}

.form-group {
  .ant-form-item {
    margin-bottom: 0;

    .ant-form-item-extra {
      .paragraph-s;
      .text-n-700;
    }

    .ant-form-item-label {
      padding: 0;
      line-height: 1;
      .mb-8;

      @media @screen767 {
        margin-bottom: 6px;
      }

      > label {
        .text-small-bold;

        @media @screen767 {
          .text-md-bold;
        }
      }
    }
  }
}

/*button css end here*/
/*badge css start here*/

.ant-tag {
  max-height: 30px;
  min-height: 30px;
  min-width: 30px;
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 6px 8px 6px 12px;
  gap: 4px;
  border-radius: 99px;
  border: 0;

  &.prefix-icon {
    padding: 6px 12px 6px 8px;
    flex-direction: row;
  }

  svg {
    transform: translateX(0);
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  &:hover {
    svg {
      transform: translateX(4px);
    }
  }
}

.tag_grid {
  display: flex;
  flex-wrap: wrap;

  .ant-tag {
    margin-bottom: 8px;
    .text-tiny;
  }

  .section-header {
    width: 100%;
  }
}

.taghero {
  padding: 89px 24px 81px 24px;

  @media @screen767 {
    padding: 63px 24px;
  }

  .title {
    .display-h1;
    .text-center;

    @media @screen767 {
      .display-h3;
    }
  }
}

/*badge css end here*/
/*breadcrumb css start here*/
.ant-breadcrumb {
  ol,
  ul {
    display: flex;
    align-items: center;

    li {
      display: flex;
      align-items: center;

      .ant-breadcrumb-separator {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 4px;
        margin-right: 8px;
      }

      a {
        height: auto;
        color: inherit;
      }

      .ant-breadcrumb-separator {
        color: inherit;
      }

      svg {
        vertical-align: middle;
      }
    }
  }
}

/*breadcrumb css end here*/
/*card css start here*/
.small-card {
  display: flex;

  img {
    flex: 0 0 80px;
  }

  .card-description {
    padding: 16px 16px 16px 12px;
    background: #ffffff;
    flex: 1;
    height: 80px;
    overflow: hidden;

    .card-dc {
      display: block;
      display: -webkit-box;
      max-width: 100%;
      height: 30px;
      margin: 0 auto;
      font-size: 14px;
      line-height: 1;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.cs-card {
  .cs-card-img {
    position: relative;
    height: 192px;
  }

  .cs-card-description {
    padding: 24px 16px;
    background: @tq-neutral-0;

    .card-dc {
      display: block;
      display: -webkit-box;
      max-width: 100%;
      height: 48px;
      font-size: 14px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .card-button-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 -6px;

      .ant-btn {
        width: calc(50% - 12px);
        margin: 0 6px;
        flex: 1;
      }
    }
  }
}

.featuredcard {
  width: 100%;
  height: 525px;

  .featuredcard-dec {
    padding: 0 16px 24px 16px;
    .absolute;
    bottom: 0;
    width: 100%;
  }
}

/*card css end here*/
/*ant switch css start here*/
// .ant-switch {
//   min-width: 25px;
//   width: 25px;
//   height: 16px;
//   border: 1px solid @tq-neutral-900;

//   &[aria-checked='false'] {
//     .ant-switch-handle {
//       &::before {
//         background: @tq-neutral-900;
//       }
//     }
//   }

//   .ant-switch-handle {
//     height: 12px;
//     width: 12px;
//     top: 1px;
//   }

//   &.ant-switch-checked {
//     .ant-switch-handle {
//       inset-inline-start: calc(100% - 14px);
//     }
//   }
// }

/*ant switch css end here*/
/*datepicker css start here*/
.ant-picker-dropdown {
  .ant-picker-date-panel {
    max-width: 326px;
    width: 100%;
  }

  .ant-picker-panel-container {
    background: @tq-neutral-200;
    border: 1px solid @tq-border-bottom;
    border-radius: 8px;
    box-shadow: @tq-shadow-s;

    .ant-picker-panel {
      padding: 0 16px;
      width: 100%;
    }
  }

  .ant-picker-header {
    padding: 0;
    border-bottom: 1px solid @tq-border-bottom;

    .ant-picker-header-next-btn,
    .ant-picker-header-prev-btn {
      color: @tq-neutral-900;
    }
  }

  .ant-picker-date-panel {
    .ant-picker-body {
      padding: 16px 0;

      .ant-picker-content {
        width: 100%;

        th {
          font: @tq-text-tiny-bold;
          color: @tq-neutral-700;
          height: 16px;

          @media @screen767 {
            font: @tq-text-small-bold;
            height: 14px;
          }
        }

        .ant-picker-cell-in-view {
          .ant-picker-cell-inner {
            font: @tq-text-small;
            min-width: 36px;
            height: 36px;
            line-height: 36px;

            @media @screen767 {
              font: @tq-text-md;
              min-width: 32px;
              height: 32px;
              line-height: 32px;
              width: 32px;
            }
          }

          &.ant-picker-cell-selected {
            .ant-picker-cell-inner {
              font: @tq-text-small;
              height: 36px;
              width: 36px;
              line-height: 36px;
              border-radius: 8px;

              @media @screen767 {
                font: @tq-text-md;
                min-width: 32px;
                height: 32px;
                line-height: 32px;
                width: 32px;
              }

              &::before {
                border-radius: 8px;
              }
            }
          }

          &.ant-picker-cell-today {
            .ant-picker-cell-inner {
              font: @tq-text-small;
              height: 36px;
              width: 36px;
              line-height: 36px;
              border-radius: 8px;

              @media @screen767 {
                font: @tq-text-md;
                min-width: 32px;
                height: 32px;
                line-height: 32px;
                width: 32px;
              }

              &::before {
                border-radius: 8px;
              }
            }
          }
        }

        .ant-picker-cell-start {
          .ant-picker-cell-inner {
            font: @tq-text-small;

            min-width: 36px;
            height: 36px;
            line-height: 36px;

            @media @screen767 {
              font: @tq-text-md;
              min-width: 32px;
              height: 32px;
              line-height: 32px;
              width: 32px;
            }
          }
        }
      }
    }
  }

  .ant-picker-header-view {
    button {
      font: @tq-text-md-bold;
      color: @tq-neutral-900;
      line-height: 52px;

      @media @screen767 {
        font: @tq-text-lg-bold;
        line-height: 52px;
      }
    }
  }

  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn {
    display: none;
  }

  .ant-picker-panel {
    .ant-picker-footer {
      border: 0;

      .ant-picker-today-btn {
        display: none;
      }

      .ant-picker-footer-extra {
        border: 0;
      }
    }
  }
}

.datepicker-footer {
  display: flex;
  justify-content: space-between;
  margin: 0 -8px;
  padding-bottom: 16px;

  button {
    width: calc(50% - 16px);
    margin: 0 8px;
  }
}

/*datepicker css end here*/
/*progressbar css start here*/
.ant-progress {
  margin: 0;

  &.ant-progress-show-info {
    .ant-progress-outer {
      padding: 0;
    }
  }

  .ant-progress-outer {
    .ant-progress-inner {
      background: @tq-neutral-0;
      border: 1px solid @tq-ivory;
    }

    .ant-progress-bg {
      background: @tq-neutral-900;
    }
  }

  .ant-progress-text {
    display: none;
  }
}

/*progressbar css end here*/
/*ant model css start here*/
.ant-modal {
  .ant-modal-content {
    border-radius: 0;
    background: @tq-neutral-0;

    .ant-modal-close {
      top: 28px;
      color: @tq-neutral-900;
    }

    .ant-modal-header {
      // background: @tq-neutral-0;
      // padding: 24px;
      // padding-bottom: 0;
      // border: 0;

      .ant-modal-title {
        font: @tq-heading-h4;
        width: 100%;
        padding: 10px;
        padding-right: 42px;
      }
    }

    // .ant-modal-body {
    //   padding: 16px 24px;
    // }

    // .ant-modal-footer {
    //   border: 0;
    //   .pl-24;
    //   .pr-24;
    //   .pb-24;
    //   .pt-0;

    //   @media @screen540 {
    //     display: flex;
    //   }

    //   .ant-btn {
    //     line-height: 20px;
    //     .pl-24;
    //     .pr-24;

    //     @media @screen540 {
    //       flex: 1 1;
    //       width: 100%;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       padding-left: 0;
    //       padding-right: 0;
    //     }
    //   }
    // }
  }

  &.fc-model {
    @media @screen540 {
      max-width: 100%;
      top: 0;
      height: 100%;
      padding: 0;
    }

    .ant-modal-content {
      @media @screen540 {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .ant-modal-body {
        @media @screen540 {
          flex: 1;
        }
      }
    }
  }
}

/*ant modal css end here*/
/*ant tab css start here*/
.ant-tabs {
  .ant-tabs-nav-list {
    .ant-tabs-tab {
      margin: 0;
      padding: 10px 16px 16px 16px;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      .text-md-bold;
    }
  }

  .ant-tabs-tab-btn {
    line-height: 20px;
    color: @tq-neutral-900;
    font: @tq-text-md;
  }
}

/*ant tab css end here*/
/*ant segment css start here*/
.ant-segmented {
  overflow: hidden;
  padding: 0;
  border-radius: 8px;
  background: transparent;

  .ant-segmented-group {
    background: @tq-neutral-0;

    .ant-segmented-item-label {
      min-height: 32px;

      padding: 0 16px;
      font: @tq-text-md-bold;
      line-height: 32px;
    }

    .ant-segmented-item-selected {
      background: @tq-neutral-900;
      color: @tq-neutral-0;

      border-radius: 8px;
    }
  }
}

/*ant segment css end here*/
/*tooltip css start here*/
.ant-tooltip {
  .ant-tooltip-inner {
    padding: 10px;
    font: @tq-text-small;
    background-color: @black-light;

    @media @screen767 {
      padding: 8px;
      font: @tq-text-md;
    }
  }
}

/*tooltip css end here*/
/*slider css start here*/
.video-controller {
  display: flex;
  align-items: center;

  .ant-slider {
    flex: 1;
    padding-block: 0;
    height: 18px;
    margin: 0 8px;

    .ant-slider-handle {
      opacity: 0;
      inset-block-start: 0;
    }

    .ant-slider-step {
      top: 0;
    }
  }
}

/*slider css end here*/
/*pagination css start here*/
.ant-pagination {
  .ant-pagination-jump-next {
    .ant-pagination-item-container {
      .ant-pagination-item-ellipsis {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        line-height: 1;
        color: inherit;
      }
    }
  }

  .ant-pagination-prev {
    &:hover {
      button {
        border-color: inherit;
        background-color: inherit;
      }
    }

    &:active {
      button {
        border-color: inherit;
        background-color: inherit;
      }
    }

    button {
      color: inherit;
    }
  }
}

/*pagination css end here*/

/*****************module component css start here************************/

/*section header css start here*/
.section-header {
  margin-bottom: 16px;

  .sh-top {
    display: flex;
    justify-content: space-between;

    .sh-title {
      .heading-h3;

      @media @screen767 {
        .heading-h5;
      }
    }

    a {
      .text-n-800;

      .gap-4;

      svg {
        position: relative;
        transform: translateX(0);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        .text-n-900;

        svg {
          transform: translateX(4px);
        }
      }
    }
  }

  .vc-paragraph {
    .text-md;
    letter-spacing: 0.02em;
    margin-bottom: 0;

    @media @screen767 {
      .text-small;
    }
  }
}

/*section header css end here*/

/*category card css start here*/
.one-side-carousel {
  .slick-list {
    padding-left: 48px;

    @media @screen1150 {
      padding-left: 24px;
    }

    @media @screen767 {
      padding-left: 16px;
    }
  }
}

.ant-carousel .slick-track {
  .d-flex;
}

/*****************module component css end here************************/
fieldset {
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  border-color: #8b8b8b;
  border-width: 1px;
}

/*ant drawer css start here*/
.ant-drawer {
  .cs-ant-drawer {
    border-radius: 8px 0 0 0;

    .ant-drawer-header {
      padding: 16px;
      background: @tq-neutral-400;
      border-bottom: 1px solid @tq-border-bottom;

      .ant-drawer-header-title {
        flex-direction: row-reverse;

        .ant-drawer-title {
          font: @tq-display-h4;
          letter-spacing: 0.01em;
        }

        .ant-drawer-close {
          margin: 0;
          color: @tq-neutral-1000;
        }
      }
    }

    .ant-drawer-body {
      background: @tq-neutral-200;
      padding: 0;
    }

    .ant-tabs-nav {
      background: @tq-neutral-400;
    }

    .ant-tabs-content-holder {
      padding: 0 16px;
    }
  }
}

/*ant drawer css end here*/
/*radio and checkbox css start here*/
.ant-radio-wrapper,
.ant-checkbox-wrapper {
  font: @tq-text-small;

  @media @screen767 {
    font: @tq-text-md;
  }
}

/*radio and checkbox css end here*/
/*shadow arrow css start here*/
.shadow-arrow {
  .ant-carousel .slick-list .slick-slide {
    pointer-events: auto;
  }

  .slick-arrow {
    z-index: 1;

    &.slick-next {
      position: absolute;
      width: 64px;
      height: 100%;
      top: 0;
      right: 0;
      background-image: url('../../../assets/images/slick-btn.svg');
      background-repeat: no-repeat;
      background-position: center;
      margin: 0;

      &::before {
        content: '';
        // background-image: url("/images/slick-btn.svg");
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
      }

      &.slick-disabled {
        display: none !important;
      }
    }

    &.slick-prev {
      position: absolute;
      width: 64px;
      height: 100%;
      top: 0;
      left: 0;
      background-image: url('../../../assets/images/slick-btn.svg');
      background-repeat: no-repeat;
      background-position: center;
      transform: scale(-1);
      margin: 0;

      &::before {
        content: '';
        // background-image: url("/images/slick-btn.svg");
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        transform: scale(1);
      }

      &.slick-disabled {
        display: none !important;
      }
    }

    @media @screen767 {
      display: none !important;
    }
  }
}

/*shadow arrow css end here*/
/*spacer css start here*/
.s-48 {
  padding-bottom: 48px;
}

.s-40 {
  padding-bottom: 40px;
}

.s-32 {
  padding-bottom: 32px;
}

.s-24 {
  padding-bottom: 24px;
}

.s-16 {
  padding-bottom: 16px;
}

.s-8 {
  padding-bottom: 8px;
}

/*spacer css end here*/
/*error  page css start here*/
.error-page-wrapper {
  height: calc(100vh - 180px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  @media @screen767 {
    height: calc(100vh - 350px);
  }

  &::before {
    content: '';
    position: absolute;
    height: 30%;
    width: 30%;
    left: 0;
    top: 0;
    // background-image: url(/images/Focused-top.svg);
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &::after {
    content: '';
    position: absolute;
    height: 30%;
    width: 30%;
    right: 0;
    bottom: 0;
    // background-image: url(/images/Focused-bottom.svg);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .error-heading {
    font-family: @tq-secondary-font;
    font-style: normal;
    font-weight: 400;
    font-size: 120px;
    line-height: 110%;

    @media @screen767 {
      font-size: 90px;
    }
  }
}

/*error page css end here*/
.ant-carousel .slick-list .slick-slide {
  pointer-events: auto;
}

.tag_carousel {
  overflow: hidden;
  display: block;
}

.ant-badge {
  &.badge-l {
    padding: 5px 18px 7px 18px;
    border-radius: 200px;
    .text-md-bold;

    @media @screen767 {
      padding: 5px 16px 7px 16px;
    }
  }

  &.badge-md {
    padding: 4px 16px 7px 16px;
    border-radius: 200px;
    .text-small-bold;

    @media @screen767 {
      padding: 5px 12px 6px 12px;
    }
  }

  &.badge-xs {
    padding: 4px 12px 6px 12px;
    border-radius: 200px;
    .text-tiny;

    @media @screen767 {
      padding: 3px 8px 4px 8px;
    }
  }
}

.ant-input-affix-wrapper {
  min-height: 44px;

  .ant-input {
    min-height: 100%;
    max-height: 100%;
  }
}

.ant-input {
  min-height: 44px;

  padding: 12px 16px;

  @media @screen767 {
    .text-lg;
    padding: 13px 12px;
  }
}

.cs-radiogroup {
  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    @media @screen767 {
      gap: 12px;
    }

    .ant-radio-button-wrapper {
      border-radius: 8px;
      text-align: center;
      min-width: 60px;
      border: 1px solid @tq-neutral-900;
      flex: 1;
      padding: 0;

      @media @screen767 {
        min-width: 40px;
        height: 40px;
      }

      &.ant-radio-button-wrapper-checked {
        background: @tq-neutral-900;
        color: @tq-neutral-100;
      }

      span {
        .paragraph-m;

        @media @screen767 {
          .button-m;
        }
      }

      &::before {
        display: none;
      }
    }
  }
}

.form-group {
  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    background: @tq-neutral-400;
  }

  .react-tel-input {
    .form-control {
      width: 100%;
      border-width: 1px;
      border-style: solid;
      border-color: @tq-border-bottom;
      border-radius: 8px;
      background-color: #fbf7f1;
      height: 44px;
      padding-left: 82px;
    }

    .flag-dropdown {
      width: 66px;
      background: @tq-neutral-400;
      border-radius: 8px 0 0 8px;

      &.open {
        background: @tq-neutral-400;

        .selected-flag {
          background: @tq-neutral-400;
        }
      }

      .selected-flag {
        width: 100%;
        padding: 0 0 0 14px;

        &[title=''] {
          .flag {
            // background-image: url(/images/fleg-null.svg);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            height: 24px;
            width: 24px;
            left: 11px;
            top: 12px;

            @media @screen767 {
              left: 9px;
              top: 14px;
            }
          }
        }

        .arrow {
          height: 16px;
          width: 16px;
          border: 0;
          // background-image: url(/images/chevron-down.svg);
          background-position: center;
          background-repeat: no-repeat;
          transform: translateY(-50%);
          left: 28px;
        }
      }
    }
  }
}

/*table page css start here*/
.table-banner {
  padding-top: 144px;
  padding-bottom: 16px;
  position: relative;

  @media @screen767 {
    padding-top: 40px;
    padding-bottom: 34px;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  h2 {
    .display-h2;
    max-width: 49.182%;
    position: relative;

    @media @screen767 {
      .display-h3;
      max-width: 41.11%;
    }
  }
}

.table-second-section {
  padding-top: 32px;
  padding-bottom: 86px;

  @media @screen767 {
    padding-top: 24px;
    padding-bottom: 48px;
  }

  .ant-row {
    @media @screen767 {
      flex-direction: column-reverse;
    }
  }
}

.steps-wrapper {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

.table-steps {
  box-shadow: 0px 19px 8px rgba(78, 68, 54, 0.02),
    0px 5px 7px rgba(78, 68, 54, 0.13);
  border-radius: 16px;
  padding: 24px;
  background: @tq-neutral-200;

  @media @screen767 {
    background: transparent;
    padding: 0;
    box-shadow: unset;
    margin-bottom: 40px;
  }

  button {
    @media @screen767 {
      width: 100%;
      padding: 0 24px;
    }
  }

  p {
    .paragraph-l;
    margin-bottom: 20px;

    @media @screen767 {
      margin-bottom: 16px;
    }
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;

    @media @screen767 {
      gap: 12px;
      margin-bottom: 24px;
    }

    li {
      .heading-h4;
      display: flex;
      align-items: center;

      @media @screen767 {
        .heading-h6;
      }

      span {
        height: 28px;
        width: 28px;
        background: @tq-red-2;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @tq-neutral-0;
        .label-l;
        .mr-12;
        flex-shrink: 0;

        @media @screen767 {
          height: 24px;
          width: 24px;
          .label-m;
          .mr-8;
        }
      }
    }
  }
}

.table-intro {
  box-shadow: 0px 19px 8px rgba(78, 68, 54, 0.02),
    0px 5px 7px rgba(78, 68, 54, 0.13);
  border-radius: 16px;
  padding: 24px;
  background: @tq-neutral-200;

  @media @screen767 {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }

  button {
    @media @screen767 {
      margin-top: 16px;
      padding: 0 24px;
      width: 100%;
    }
  }

  .table-dec {
    padding-top: 20px;

    h4 {
      .display-h4;
      margin-bottom: 4px;
    }

    p {
      .paragraph-l;
    }
  }

  .table-intro-img {
    padding-top: 58%;
    // background-image: url(/images/intro-bg.svg);
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    position: relative;
  }
}

/*table page css end here*/
.sitemap-links {
  list-style: none;

  li {
    .button-l;
    .mb-8;

    a {
      .text-n-900;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

.fixed-btn-responsive {
  @media @screen767 {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 16px;
    background: @tq-neutral-200;
    width: 100%;
  }

  button {
    @media @screen767 {
      width: 100%;
    }
  }
}

.filterhide {
  .filter-block {
    display: none;

    @media @screen991 {
      display: flex;
    }
  }
}

.filter-block {
  background: #faf4e8;
  padding: 24px;
  box-shadow: 0px 19px 8px rgba(78, 68, 54, 0.02),
    0px 5px 7px rgba(78, 68, 54, 0.13);
  border-radius: 8px;

  @media @screen991 {
    height: 100%;
    box-shadow: unset;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .filter-body {
    .mb-16;

    @media @screen991 {
      flex: 1;
      overflow: auto;
      padding: 12px 24px;
      margin: 0;
    }
  }

  .filter-item {
    .filter-title {
      .heading-h6;
      .mb-8;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid @tq-neutral-500;
      padding-bottom: 12px;
      margin-bottom: 12px;
    }
  }

  .filter-button-group {
    display: flex;
    gap: 12px;

    @media @screen991 {
      padding: 24px;
      background: @tq-ivory;
    }

    button {
      flex: 1;
      padding: 0;
    }
  }
}

.filter-checkbox-group {
  .ant-checkbox-group {
    flex-direction: column;
    gap: 8px;

    .ant-checkbox-wrapper {
      margin: 0;
    }
  }
}

.filter-drawer {
  .ant-drawer-body {
    overflow: hidden;
  }
}

.search-filter {
  display: flex;
  gap: 12px;

  @media @screen991 {
    gap: 8px;
  }

  input {
    flex: 1;
  }

  button {
    height: 44px;
    width: 44px;
    min-width: 44px;
    border-radius: 50%;
    padding: 0;
  }
}

/*steps css start here*/
.cs-steps {
  &.ant-steps {
    counter-reset: topx;

    &.ant-steps-vertical {
      flex-direction: row;
    }

    .ant-steps-item {
      flex: unset;

      &.ant-steps-item-finish {
        .ant-steps-item-container {
          .ant-steps-item-title {
            .text-small;
            .text-n-700;
          }
        }

        .ant-steps-icon {
          &::before {
            content: counter(topx);
            counter-increment: topx;
          }

          span {
            display: none;
          }
        }

        .ant-steps-item-container .ant-steps-item-icon {
          background: @tq-neutral-500;
        }
      }
    }

    .ant-steps-item-container {
      display: flex;
      align-items: center;
    }

    .ant-steps-item-wait {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          background: @tq-neutral-500;

          .ant-steps-icon {
            .text-n-1000;
          }
        }

        .ant-steps-item-title {
          .text-small;
          padding-inline-end: 8px;
        }
      }
    }

    .ant-steps-item-container {
      .ant-steps-item-tail {
        display: none;
      }

      .ant-steps-item-content {
        min-height: 0;
      }

      .ant-steps-item-icon {
        width: 28px;
        height: 28px;
        .label-l;
        line-height: 26px;
        background: @tq-red-2;
        border: 0;
        margin-inline-end: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        @media @screen767 {
          width: 20px;
          height: 20px;
          .text-small;
          margin-inline-end: 4px;
        }

        svg {
          fill: #fff;
        }
      }

      .ant-steps-item-title {
        .text-md-bold;
        padding-inline-end: 8px;

        @media @screen767 {
          .text-small-bold;
          white-space: nowrap;
        }

        &::after {
          display: none;
        }
      }
    }
  }
}

/*steps css end here*/
.ant-select {
  outline: none;
}

.section-page {
  padding-top: 24px;
  padding-bottom: 72px;

  @media @screen767 {
    padding-top: 16px;
    padding-bottom: 48px;
  }
}

.search-page {
  padding-top: 32px;

  .container {
    @media @screen767 {
      padding: 0;
    }
  }

  .search-filter {
    max-width: 589px;

    @media @screen767 {
      padding: 0 16px;
    }
  }

  .search-grid {
    display: flex;

    .filter-block {
      max-width: 300px;
      width: 100%;
      margin-right: 40px;
    }

    .container {
      padding: 0;
    }
  }
}

.contributor-landing-page {
  padding-bottom: 72px;

  @media @screen767 {
    padding-bottom: 0;
  }

  .FilterTagList {
    margin-bottom: 16px;

    @media @screen767 {
      display: none;
    }
  }

  .ctacard .cta-button-wrapper button {
    @media @screen767 {
      width: 100%;
      margin: 0;
    }
  }

  .contributor-landing-videos {
    padding-top: 32px;
    padding-bottom: 40px;

    @media @screen767 {
      padding-top: 24px;
    }

    .search-filter {
      max-width: 589px;
      margin-bottom: 16px;
    }

    .section-header {
      margin-bottom: 24px;

      @media @screen767 {
        margin-bottom: 20px;
      }
    }
  }
}

.FilterTagList {
  display: flex;
  flex-shrink: 0;
  overflow: auto;
  gap: 8px;

  button {
    &.cs-button {
      border-radius: 100px;
      height: 33px;
      padding: 6px 16px 9px 16px;
      .text-small-bold;

      @media @screen767 {
        height: 30px;
        .text-md-bold;
      }
    }
  }
}

.taglanding-page {
  .section-page {
    padding-top: 32px;

    @media @screen767 {
      padding-top: 24px;
    }
  }

  .section-header .sh-top .sh-title {
    margin-bottom: 8px;

    @media @screen767 {
      margin-bottom: 0;
    }
  }
}

.categorylanding-page {
  padding-bottom: 72px;

  @media @screen767 {
    padding-bottom: 48px;
  }

  .video-list-grid .section-header {
    padding-bottom: 12px;

    @media @screen767 {
      margin: 0;
    }
  }

  .video-card.videolist .video-image {
    width: 32.271%;
    padding-top: 18.128%;

    @media @screen767 {
      width: 34.989%;
      padding-top: 19.826%;
    }
  }

  .featured-video-card {
    padding: 40px 0;

    @media @screen767 {
      padding-top: 0;
      padding-bottom: 32px;
    }
  }

  .video-carousel-section {
    .section-header {
      margin-bottom: 16px;

      .sh-title {
        .heading-h4;

        @media @screen767 {
          .heading-h6;
        }
      }

      .sh-top {
        align-items: flex-start;

        a {
          .text-md-bold;
        }
      }
    }
  }

  .featured-collection {
    padding: 40px 0;

    @media @screen767 {
      padding: 32px 0;
    }
  }

  .video-list-filter-grid {
    display: flex;
    align-items: flex-start;
    gap: 40px;

    .filter-block {
      max-width: 300px;
      width: 100%;
    }

    .video-list-filter-grid-right {
      .search-filter {
        max-width: 592px;
        margin-bottom: 12px;
      }

      .container,
      .container-rnp {
        padding: 0;
      }
    }
  }
}

.video-list-filter-section {
  .container {
    @media @screen767 {
      padding: 0;
    }
  }

  .section-header {
    @media @screen767 {
      padding: 0 16px 12px 16px;
    }
  }

  .search-filter {
    @media @screen767 {
      padding: 0 16px 0 16px;
      margin-bottom: 16px;
    }
  }
}

.collectionlanding-page {
  &.search-page {
    padding-top: 0;

    .search-filter {
      @media @screen767 {
        padding: 0;
      }
    }

    .container {
      @media @screen767 {
        padding: 0 16px;
      }

      .container {
        @media @screen767 {
          padding: 0;
        }
      }
    }
  }

  .section-page {
    padding-top: 32px;

    @media @screen767 {
      padding-top: 24px;
    }
  }

  .FilterTagList {
    margin-top: 16px;
    margin-bottom: 8px;

    @media @screen767 {
      margin-bottom: 0;
    }
  }
}

.Video-detail-page {
  padding-bottom: 72px;

  @media @screen767 {
    padding-bottom: 0;
  }

  .video-info-wrapper {
    padding-top: 24px;

    @media @screen767 {
      padding-bottom: 24px;
    }
  }

  .s-40 {
    @media @screen767 {
      padding-bottom: 32px;
    }
  }
}

.table-invite-page {
  padding: 32px 0 75px 0;

  @media @screen767 {
    padding: 24px 0 48px 0;
  }

  .table-invite-grid {
    display: flex;
    gap: 40px;

    @media @screen1150 {
      gap: 20px;
    }

    @media @screen991 {
      gap: 24px;
      flex-direction: column-reverse;
      padding: 0 16px;
    }

    &:first-child {
      @media @screen991 {
        flex-direction: column;
        gap: 44px;
        padding: 0;
      }

      .table-invite-left {
        @media @screen991 {
          width: 100%;
        }

        @media @screen767 {
          padding: 0 16px 0 16px;
        }
      }
    }

    .table-invite-left {
      width: 31.25%;

      @media @screen991 {
        width: 100%;
      }

      .tableCard {
        max-width: 100%;
      }

      .image-tbl {
        position: relative;
        padding-top: 58.098%;
        border-radius: 16px;
        overflow: hidden;
      }
    }

    .table-invite-right {
      width: 65.774%;

      @media @screen991 {
        width: 100%;
      }

      .content {
        h3 {
          .display-h3;
          .mb-4;

          @media @screen767 {
            .display-h4;
          }
        }

        p {
          .paragraph-l;
        }
      }
    }
  }

  .s-40 {
    @media @screen767 {
      padding-bottom: 32px;
    }
  }
}

.table-rsvp-account-page {
  padding-top: 32px;
  padding-bottom: 72px;

  @media @screen767 {
    padding-top: 24px;
    padding-bottom: 48px;
  }

  .table-rsvp-block {
    .bg-n-200;
    // box-shadow: @tq-shadow-m;
    max-width: 480px;
    margin: auto;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 45px 18px rgba(78, 68, 54, 0.02),
      0px 25px 15px rgba(78, 68, 54, 0.08), 0px 11px 11px rgba(78, 68, 54, 0.13),
      0px 3px 6px rgba(78, 68, 54, 0.15), 0px 0px 0px rgba(78, 68, 54, 0.15);

    @media @screen767 {
      max-width: 100%;
      border-radius: 8px;
      box-shadow: unset;
      .bg-ivory;
    }

    .table-rsvp-header {
      padding: 24px 30px 44px 30px;

      @media @screen767 {
        padding: 0 16px 28px 16px;
      }

      .tableCard {
        max-width: 100%;
      }
    }

    .table-rsvp-body {
      padding: 0 24px 24px 24px;

      @media @screen767 {
        padding: 0 16px;
      }
    }

    .rsvp-btn {
      margin-top: 16px;

      @media @screen767 {
        margin-top: 0;
      }
    }
  }

  .cs-devider {
    max-width: 351px;
    width: 100%;
    margin: auto;

    @media @screen767 {
      max-width: 239px;
    }
  }

  ol {
    list-style-position: inside;
    margin-top: 12px;

    li {
      .paragraph-l;
    }
  }
}

.cs-devider {
  .ant-divider-with-text {
    border-block-start: 0 @tq-neutral-600;
    margin: 24px 0;

    @media @screen767 {
      margin: 20px 0;
    }

    .ant-divider-inner-text {
      .label-l;
      .text-n-600;
      text-transform: uppercase;

      @media @screen767 {
        .label-xl;
      }
    }
  }
}

.donate-page {
  &.table-rsvp-success {
    .donate-block {
      max-width: 528px;

      .donate-form {
        padding: 24px;

        .donation-successful {
          padding-top: 16px;
        }

        .gif-img {
          padding-top: 206px;
          width: 206px;
          margin: auto;

          @media @screen767 {
            padding-top: 150px;
            width: 150px;
          }
        }

        .see-table {
          .text-md-bold;
          margin-top: 16px;
        }
      }
    }
  }
}

.allcontributor-page {
  .section-header {
    margin-bottom: 24px;

    @media @screen767 {
      margin-bottom: 16px;
    }
  }
}

.all-filter-tags {
  .FilterTagList {
    flex-wrap: wrap;
  }
}

.video-list-page {
  .video-list-grid {
    .section-header {
      @media @screen767 {
        padding: 0 16px 16px 16px;
      }
    }

    .container {
      @media @screen767 {
        padding: 0;
      }
    }
  }
}

.home-page {
  padding-top: 24px;
  padding-bottom: 72px;

  @media @screen767 {
    padding-bottom: 48px;
    padding-top: 0;
  }

  .featured-carousel,
  .video-carousel,
  .category-slider,
  .featured-collection,
  .contributor-carousel,
  .collection-carousel {
    margin-bottom: 40px;

    @media @screen767 {
      margin-bottom: 32px;
    }
  }

  .section-header .sh-top .sh-title {
    .heading-h4;

    @media @screen767 {
      .heading-h6;
    }
  }

  .s-40 {
    @media @screen767 {
      padding-bottom: 32px;
    }
  }
}

//article carousel v2
.cta-carousel-section {
  padding: 40px 0;
  overflow: hidden;

  h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
    margin-bottom: 32px;
  }

  .slick-track {
    display: flex;

    & > div {
      margin: 0 8px;
    }

    .slick-slide {
      height: auto;

      & > div {
        height: 100%;
      }
    }
  }

  .cta-carousel-card {
    border-radius: 16px;
    background: @tq-blue-2;
    padding: 20px;
    max-width: 280px;
    min-width: 280px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .heading {
      display: flex;
      align-items: baseline;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.24px;
      gap: 6px;
      margin-bottom: 8px;
      .title {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 84px;
      }
      svg {
        flex-shrink: 0;
      }
    }

    .description {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      /* 22.4px */
      letter-spacing: 0.32px;
      flex: 1;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 178px;
    }

    a {
      margin-top: 20px;
      color: @tq-neutral-900;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.18px;
      display: inline-flex;
      gap: 6px;
      align-items: center;
    }
  }
}

//Video Player V2
.video-info {
  .video-info-block {
    padding: 16px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    @media @screen767 {
      padding: 12px;
    }
    @media @screen375 {
      gap: 12px;
    }
    .video-info-left {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 16px;
      @media @screen375 {
        gap: 12px;
      }
      .video-thumb-img {
        position: relative;
        width: 100px;
        padding-top: 56px;
        border-radius: 4px;
        overflow: hidden;
        flex-shrink: 0;
        cursor: pointer;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        @media @screen375 {
          width: 80px;
          padding-top: 48px;
        }
        .ant-image {
          position: absolute;
          inset: 0;
        }
      }

      .video-name {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 115px;
        @media @screen375 {
          font-size: 18px;
        }
      }
    }

    .video-info-right {
      display: flex;
      gap: 16px;
      align-items: center;
      .cs-button {
        padding: 0;
      }
      @media @screen375 {
        gap: 12px;
      }
    }
  }
}
