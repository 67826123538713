.rbc-calendar {
  cursor: pointer;
  min-height: 710px;
  height: 78vh;
  font-size: 18px;
  .rbc-month-view,
  .rbc-month-row,
  .rbc-day-bg,
  .rbc-header,
  .rbc-time-view,
  .rbc-events-container,
  .rbc-time-content,
  .rbc-time-slot,
  .rbc-time-header,
  .rbc-time-header-content {
    border-color: #8b8b8b;
  }

  .rbc-day-bg.rbc-today,
  .rbc-today {
    background-color: #707070;
  }
  .rbc-day-bg.rbc-off-range-bg {
    background: #b0adab;
  }
  .rbc-toolbar-label {
    font-size: 20px;
    font-weight: 500;
  }
}

.rbc-toolbar {
  .rbc-btn-group {
    display: flex;
    button {
      font-size: 16px;
      border-color: #8b8b8b;
    }
    button:hover,
    button:active,
    button:focus {
      background: none;
      cursor: pointer;
    }
    button.rbc-active {
      background: #3a3a3a;
      color: #b6b4b3;
    }
  }
}

.rbc-btn-group button + button {
  margin-left: 0;
}

.rbc-timeslot-group {
  border-bottom: none;
  min-height: 75px;
}

.rbc-month-header {
  height: 30px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.rbc-time-view {
  .rbc-time-content {
    border-top: none;
  }
}

.rbc-event {
  order: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: black;
  border-radius: 5px;
  color: #b6b4b3;
  cursor: pointer;
  width: 100%;
  text-align: left;
  .rbc-event-content {
    white-space: unset;
    font-size: 16px;
    line-height: 14px;
  }
  &.rbc-selected {
    background-color: black;
  }
}

.past-dates {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.25);
}

.calendar-div {
  margin: 24px;
}
